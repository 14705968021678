//-------------------------------------colors--------------------------
$dark-blue: #26354c;
$main-blue: #0091ff;
$medium-blue: #046fc4;
$bg-grey: #dfe2eb;
$bg-footer: rgba(255, 255, 255, 0.62);
$bg-navbar: #e9ebf1;
$font-color-grey: #333333;
$font-color-orange: #ff7a00;

//-------------------------------------fonts--------------------------

@font-face {
  font-family: "Helvetica-Light";
  src: url("../assets/fonts/Helvetica\ Light\ Regular.otf");
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Roboto:wght@300;400;700&family=Spectral:wght@300;400;700&display=swap");

// $font-family: "Helvetica", monospace, "Roboto", "Oxygen";
$font-family: "Inter";

// $font-family-light: "Helvetica-Light", "Helvetica", monospace, "Roboto", "Oxygen";
$font-family-light: "Inter";

$h2-line-height-xl: 3.5rem; //56px
$h2-line-height-md: 2.625rem; //42px
$h2-line-height-sm: 2rem; //32px

$h1-xl: 3.6rem; //56px
$h1-md: 2.625rem; //42px
$h1-sm: 2rem; //32px

$h2-xl: 2.625rem; //42px
$h2-md: 2.25rem; //36px
$h2-sm: 1.75rem; //28px

$h3-xl: 2.25rem; //36px
$h3-md: 1.75rem; //28px
$h3-sm: 1.5rem; //24 px

$h4-xl: 1.75rem; //28px
$h4-md: 1.5rem; //24px
$h4-sm: 1.125rem; //18 px

$h5-xl: 1.5rem; //24px
$h5-md: 1.25rem; //18px
$h5-sm: 1rem; //16 px

$p-xl: 1.125rem;
$p-md: 1rem;
$p-sm: 0.875rem;

$size-title-xl: 2.6rem;
$size-title-md: 2.2rem;
$size-title-sm: 1.6rem;
$size-text-xl: 1rem;
$size-text-md: 0.87rem;
$size-text-sm: 0.81rem;

//-------------------------------------Breakpoints--------------------------
$desktop-small-breakpoint: 1366px;
$mobile-breakpoint: 768px;
$mobile-small-breakpoint: 455px;
