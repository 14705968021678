@import "../../styles/variables";

.footer {
  &_container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    // background-color: #dfe2eb;
  }

  display: flex;
  width: 100%;
  max-width: 1400px;
  height: auto;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  color: $font-color-grey;
  padding: 0rem 8rem;

  @media (max-width: $desktop-small-breakpoint) {
    padding: 0rem 4rem;
  }

  @media (max-width: $mobile-breakpoint) {
    padding: 0rem 2rem;
  }

  p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }

  &_info {
    display: flex;
    padding: 2rem 0rem;
    flex-wrap: nowrap;
    font-family: $font-family;
    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }

    &_center {
      @media (max-width: $mobile-breakpoint) {
        text-align: center;
      }
    }

    &_center {
      @media (max-width: $mobile-breakpoint) {
        text-align: center;
      }
    }

    &_icons {
      display: flex;
      gap: 0.5rem;
      margin-bottom: 1rem;

      @media (max-width: $mobile-breakpoint) {
        justify-content: center;
      }
    }

    &_contact {
      display: flex;
      flex-direction: column;
      width: 30%;
      font-weight: bold;
      font-size: 12px;
      @media (max-width: $mobile-breakpoint) {
        width: 100%;
        order: 2;
      }

      &_title {
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 1rem;
      }

      &_subtitle {
        color: $font-color-orange;
        text-transform: capitalize;
        letter-spacing: 0.3rem;
        font-weight: bold;
        font-size: 13px;
        margin-top: 1.3rem;
      }
      &_email {
        font-weight: normal;
      }
      &_icon {
        cursor: pointer;
      }
    }
    &_links {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 50%;
      color: $main-blue;
      font-weight: bold;
      cursor: pointer;
      @media (max-width: $mobile-breakpoint) {
        display: none;
      }

      &_title {
        color: black;
        margin-bottom: 1rem;
      }
    }

    &_link {
      margin: 1rem 0rem;
      font-size: 15px;

      &:first-child {
        margin-top: 0;
      }
    }

    &_logo {
      height: 200px;
      width: auto;
      @media (max-width: $mobile-breakpoint) {
        height: 120px;
      }
      &_container {
        display: flex;
        @media (max-width: $mobile-breakpoint) {
          justify-content: center;
          width: 100%;
          align-items: center;
        }
      }
    }
  }
}
.footer_rights {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.6rem;
  border-top: 1px solid #d8d8d8;
  font-family: $font-family-light;
  color: $font-color-grey;
  font-size: 13px;
  @media (max-width: $mobile-breakpoint) {
    font-size: 10px;
  }
}
