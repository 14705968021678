@import "../../styles/variables";
@import "../../styles/mixins";

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 105px;
  position: absolute;
  overflow: hidden;
  z-index: 20;
  background-color: $bg-navbar;

  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    height: 100%;

    padding: 0 8rem;

    @media (max-width: $desktop-small-breakpoint) {
      padding: 0 4rem;
    }

    @media (max-width: $mobile-breakpoint) {
      padding: 0 2rem;
    }
  }

  &_logo {
    height: 100%;
    flex-basis: 20%;
    display: flex;
    cursor: pointer;
    outline: none;

    &_img {
      margin: 10% 0;
      height: 80%;
      width: auto;
    }
  }

  &_hamburger {
    display: flex;
    flex-basis: 15%;
    justify-content: flex-end;
    margin-right: 5rem;

    &_icon {
      width: 35px;
      display: none;

      @media (max-width: $mobile-breakpoint) {
        display: initial;
      }
    }
    @media (max-width: $desktop-small-breakpoint) {
      flex-basis: 5%;
    }

    @media (max-width: $mobile-breakpoint) {
      margin-right: 2rem;
    }
  }

  &_links {
    display: flex;
    flex-basis: 60%;
    justify-content: space-around;
    padding-inline-start: 0px;

    @media (max-width: $desktop-small-breakpoint) {
      flex-basis: 80%;
    }
    &_li {
      list-style: none;
      margin: 0 0.5rem;
    }
    &_item {
      text-decoration: none;
      font-family: $font-family;
      font-size: 1.1rem;
      font-weight: bold;
      color: $main-blue;
      cursor: pointer;
      outline: none;

      &:hover {
        color: $medium-blue;
        transition: all 0.5s;
      }

      &:focus,
      &:hover,
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
      }

      @media (max-width: $mobile-breakpoint) {
        display: none;
      }
    }
  }
}

.menu__container {
  flex-direction: column;

  .menu {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: $bg-navbar;
    width: 100%;
    left: -100%;
    transition: 850ms;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 15;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .menu.active {
    left: 0;
    transition: 350ms;
  }

  .menu__items {
    display: flex;
    padding: 5em 0rem;
    width: 100%;
    flex-direction: column;
  }

  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    list-style: none;
    height: 75px;
    margin: 0;
    width: 100%;
    font-family: $font-family;
    color: $dark-blue;

    &:hover {
      // color: whitesmoke;
      // background-color: $medium-blue;
      text-decoration: none;
    }
  }

  .menu__item__link {
    text-decoration: none;
    font-size: 1.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    color: $main-blue;
    font-weight: bold;
  }

  .menu__icons {
    margin-left: 3%;
  }

  .menu__icon {
    margin-right: 1rem;
    margin-bottom: 0.7rem;
    cursor: pointer;
  }
}
