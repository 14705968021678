* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
  // font-family: Inter;
}

img {
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5 {
  //font-size: 100%;
  font-weight: normal;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
