.btn {
  border: none;
  border-radius: 0.5rem;
  background-color: $main-blue;
  color: white;
  font-size: 18px;
  padding: 0.8rem 90px;
  box-shadow: rgb(22, 80, 162, 0.2) 0px 8px 24px;
  z-index: 3;
  cursor: pointer;
  font-size: inherit;
  transition: ease-in 0.3s;
  &:hover {
    box-shadow: rgb(22, 80, 162, 0.4) 0px 8px 24px;
  }
  @media (max-width: 720px) {
    font-size: 14px;
    padding: 0.8rem 50px;
  }
}
